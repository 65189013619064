<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Danh sách nhóm phương tiện</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'm']" @shortkey="add()" @click="add()" class="md-raised md-primary btn-add">Thêm <span>m</span>ới<md-tooltip>Thêm mới (Ctrl + T)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="table-content">
					<table class="data-table">
						<thead>
                            <tr>
                                <th style="width: 55%;">Tên nhóm phương tiện</th>
                                <th style="width: 20%;">Mã nhóm phương tiện</th>
                                <th style="width: 20%;">Trạng thái</th>
                                <th style="width: 150px;">Hành động</th>
                            </tr>
						</thead>
						<tbody v-for="(item, index) in data" :key="item.id">
							<tr :class="{ 'odd': index % 2 !== 0 }">
								<td><b style="font-weight:500;">{{item.groupName}}</b></td>
                                <td class="center">{{item.groupCode}}</td>
                                <td class="center">
                                    <span v-if="item.isActive" class="s-active"><i class="fas fa-check"></i></span>
                                    <span v-else class="s-inactive"><i class="fas fa-check"></i></span>
                                </td>
								<td class="action">
                                    <router-link :to="'/vehicle-group/crud/' + item.id">
                                        <md-button class="md-fab md-mini md-second">
                                            <md-icon>edit</md-icon>
                                            <md-tooltip>Sửa</md-tooltip>
                                        </md-button>
                                    </router-link>
                                    <md-button v-on:click="confirmDelete(item.id)" class="md-fab md-mini md-plain">
                                        <md-icon>clear</md-icon>
                                        <md-tooltip>Xóa</md-tooltip>
                                    </md-button>
								</td>
							</tr>
                            <tr v-for="(sub, index) in item.children" :key="sub.id" :class="{ 'odd': index % 2 !== 0 }">
                                <td style="padding-left: 40px;">|_ {{sub.groupName}}</td>
                                <td class="center">{{sub.groupCode}}</td>
                                <td class="center">
                                    <span v-if="sub.isActive" class="s-active"><i class="fas fa-check"></i></span>
                                    <span v-else class="s-inactive"><i class="fas fa-check"></i></span>
                                </td>
                                <td class="action">
                                    <router-link :to="'/vehicle-group/crud/' + sub.id">
                                        <md-button class="md-fab md-mini md-second">
                                            <md-icon>edit</md-icon>
                                            <md-tooltip>Sửa</md-tooltip>
                                        </md-button>
                                    </router-link>
                                    <md-button v-on:click="confirmDelete(sub.id)" class="md-fab md-mini md-plain">
                                        <md-icon>clear</md-icon>
                                        <md-tooltip>Xóa</md-tooltip>
                                    </md-button>
								</td>
                            </tr>
						</tbody>
					</table>
				</div>
            </div>
        </div>
    </div>
</template>
<script>
    import vehicleGroupService from '../../../api/vehicleGroupService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';

    export default {
        metaInfo: {
            title: 'Danh sách nhóm phương tiện'
        },
        data() {
            return {
               loadding: false,
               data: [],
               id: 0
            }
        },
        created(){
            this.getData();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            refresh(){
                this.getData();
            },

            getData(){
                this.loadding = true;
                vehicleGroupService.getAll(true).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            confirmDelete(id){
                this.$modal.show('dialog', {
                title: 'Thông báo',
                text: 'Bạn có chắc muốn xóa không?',
                buttons: [
                        {
                            title: 'Hủy',
                            handler: () => {
                                this.$modal.hide('dialog')
                            }
                        },
                        {
                            title: 'Xóa',
                            handler: () => {
                                this.del(id)
                            }
                       }
                ]
                })
            },

            del(id){
                this.$modal.hide('dialog');
                this.setLoading(true);
                vehicleGroupService.delete(id).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Xóa thành công");
                        this.refresh();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            add(){
                this.$router.push('/vehicle-group/crud');
            }        
        }
    }

</script>
